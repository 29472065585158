import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else if (environment.appConfigDefaults?.grafana?.faro?.url) {
  initializeFaro({
    url: environment.appConfigDefaults.grafana.faro.url,
    app: {
      name: environment.appConfigDefaults.grafana.faro.name,
      version: '1.0.0',
      environment: 'test',
    },
    sessionTracking: {
      samplingRate: environment.appConfigDefaults?.grafana?.faro.sessionTracking?.samplingRate ?? 1,
      persistent: environment.appConfigDefaults?.grafana?.faro.sessionTracking?.persistent ?? false,
    },

    instrumentations: [...getWebInstrumentations(), new TracingInstrumentation()],
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const storageSupported = !!window.localStorage;

  if (!storageSupported) {
    Object.defineProperty(window, 'localStorage', {
      value: {
        _data: {},
        setItem: function (id, val) {
          return (this._data[id] = String(val));
        },
        getItem: function (id) {
          return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
        },
        removeItem: function (id) {
          return delete this._data[id];
        },
        clear: function () {
          return (this._data = {});
        },
      },
    });
    Object.defineProperty(window, 'sessionStorage', {
      value: {
        _data: {},
        setItem: function (id, val) {
          return (this._data[id] = String(val));
        },
        getItem: function (id) {
          return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
        },
        removeItem: function (id) {
          return delete this._data[id];
        },
        clear: function () {
          return (this._data = {});
        },
      },
    });
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => {
      console.error(err);
    });
});
